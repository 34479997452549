<!--
 * @Descripttion: 
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="loading">
    <div class="animation">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <div class="tips">正在加载数据，请稍候...</div>
  </div>
</template>

<script>
  import cookies from '@/plugins/utils/cookies'
  export default {
    components: {

    },
    data() {
      return {
        
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.jump()
    },
    methods: {
      // 第三方登录，页面跳转专用
      jump() {
        if (cookies.get('token'))
          this.$router.push({ name: 'home' })
      }
    }
  }
</script>

<style scoped="" lang="scss">
  .loading{
    $colors: #7ef9ff, #89cff0, #4682b4, #0f52ba, #000080;
    width: 100vw;
    height: 100vh;
    background-color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation-delay: 1s;
    .animation{
      display: flex;
      width: 200px;
      flex-wrap: wrap;
      justify-content: center;
    }
    .tips{
      color: #fff;
      margin-top: 20px;
    }
    .dot{
      position: relative;
      width: 1em;
      height: 1em;
      margin: 1em;
      border-radius: 50%;
      user-select: none;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: inherit;
        border-radius: inherit;
        left: 0;
        right: 0;
        animation: wave 2.2s ease-out infinite;
      }
      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          background: nth($colors, $i);
          &::before {
            animation-delay: $i * 0.2s;
          }
        }
      }
    }
  }
  @keyframes wave {
    50%,
    75% {
      transform: scale(2.5);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
</style>
